<template>
  <v-card class="lugar ma-2 rounded-lg" :to="`/lugar/${id}`">
    <v-img
      class="rounded-lg img"
      :src="img"
      alt="img_lugar"
      @error="errorImg"
    />
    <div align="center" class="name-cp">
      <v-card-title class="ml-1 text-left no-romper-palabras">{{
        nombre
      }}</v-card-title>
      <v-card-subtitle class="mr-1 text-right">{{
        cp != "0" ? cp : ""
      }}</v-card-subtitle>
    </div>
    <slot class="align-self-end"></slot>
  </v-card>
</template>

<script>
export default {
  props: {
    img_src: { default: require("@/assets/no_disponible.png") },
    id: { type: Number },
    nombre: { type: String },
    cp: [String, Number],
  },
  data() {
    return {
      img: this.img_src
        ? `/static/lugares/${this.img_src}`
        : require("@/assets/no_disponible.png"),
    };
  },
  methods: {
    errorImg() {
      this.img = require("@/assets/no_disponible.png");
    },
  },
};
</script>

<style lang="scss">
.lugar {
  align-self: flex-start;
  &.grid {
    max-width: 300px;
    cursor: pointer;
    transition: transform 0.2s;
    &:hover {
      transform: translateY(-10px);
    }
    .img {
      height: 300px;
      width: 300px;
    }
  }
  &.list {
    width: 100%;
    display: grid;
    grid-template-columns: 0fr 1fr;
    grid-gap: 10px;
    .img {
      border-radius: 50% !important;
      margin: 10px;
      min-width: 60px;
      min-height: 60px;
      width: 100px;
      height: 100px;
    }
    .name-cp {
      display: grid;
      & ::first-child {
        align-self: flex-end;
        padding: 0;
      }
      & ::last-child {
        align-self: flex-start;
        padding: 0;
      }
    }
  }
}
</style>